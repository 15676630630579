// src/App.js
import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, Box, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Sidebar } from './components/Sidebar';
import { HomePage } from './pages/HomePage';
import { AddFuelRecord } from './pages/AddFuelRecord';
import { ReferenceTransactionPage } from './pages/ReferenceTransactionPage';
import { CoR } from './pages/CoR';

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Create a default theme
  const theme = createTheme();

  // Detect if it's a mobile screen
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
          {/* Sidebar Component */}
          <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

          {/* Toggle button for mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' }, position: 'absolute', top: 16, left: 16 }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              padding: isMobile ? 2 : 3, // Reduced padding on mobile
              display: 'flex',
              flexDirection: 'column',
              width: '100%', // Ensure it spans the full width
              maxWidth: '100%', // Adjust sidebar width to 240px on desktop
              marginLeft: 0, // Align content correctly when sidebar is open
              marginTop: isMobile ? 4 : 0, // Push down content a bit on mobile
              overflowX: 'hidden', // Prevent horizontal overflow
            }}
          >
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/add-fuel-records" element={<AddFuelRecord />} />
              <Route path="/reference-transactions" element={<ReferenceTransactionPage />} />
              <Route path="/cor" element={<CoR />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
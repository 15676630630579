// src/components/Sidebar.js
import { useMediaQuery, List, ListItem, ListItemText, Drawer, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

export function Sidebar({ mobileOpen, handleDrawerToggle }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        width: 180,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 180,
          boxSizing: 'border-box',
          backgroundColor: '#151b54',
          color: '#ffffff',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', padding: '16px 0', color: '#ffffff' }}>
        Wallaby Fuel Tracker
      </Typography>
      <Divider sx={{ backgroundColor: '#555555' }} />
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null} // Close drawer on mobile after clicking an item
        >
          <ListItemText primary="Home" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/add-fuel-records"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Add Fuel Records" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/reference-transactions"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Upload Reference Transactions" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/cor"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Monthly CoR Check" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
      </List>
    </Drawer>
  );
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Typography, Grid, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

export const HomePage = () => {
  const [transactions, setTransactions] = useState([]);
  const [missingTransactions, setMissingTransactions] = useState([]);
  const [talliedCount, setTalliedCount] = useState(0);
  const [filterMonth, setFilterMonth] = useState(new Date().toISOString().substring(0, 7)); // Default to current month

  useEffect(() => {
    fetchTransactions(); // Load data on mount
    fetchMissingTransactions();
    fetchTalliedStatus();
  }, []);

  useEffect(() => {
    fetchTalliedStatus();
  }, [filterMonth]);

  const fetchTransactions = () => {
    axios.get('/api/get-input-transactions').then(res => {
      setTransactions(res.data);
    }).catch(error => {
      console.error('Error fetching transactions:', error);
    });
  };

  const fetchMissingTransactions = () => {
    axios.get('/api/get-missing-transactions').then(res => {
      setMissingTransactions(res.data);
    }).catch(error => {
      console.error('Error fetching transactions:', error);
    });
  };

  const fetchTalliedStatus = () => {
    console.log(filterMonth);
    axios.get('/api/get-tallied-status').then(res => {
      const talliedRecords = res.data.filter(record => record.tallied_status === 'TALLIED' && record.month === filterMonth);
      console.log(talliedRecords);
      setTalliedCount(45-talliedRecords.length);
    }).catch(error => {
      console.error('Error fetching tallied status:', error);
    });
  };

  // Filter transactions by the selected month
  const filteredTransactions = transactions.filter(txn => {
    const [txnDay, txnMonth, txnYear] = txn.date.split('/').map(Number);
    const [filterYear, filterMonthValue] = filterMonth.split('-').map(Number);
    return txnYear === filterYear && txnMonth === filterMonthValue;
  });

  // Calculate totals for Diesel and Petrol in terms of fuel usage and amount
  const calculateTotals = (data) => {
    let dieselLiters = 0, petrolLiters = 0, dieselAmount = 0, petrolAmount = 0;
    
    data.forEach(txn => {
      if (txn.type_of_fuel === 'DIESEL') {
        dieselLiters += parseFloat(txn.fuel_usage);
        dieselAmount += parseFloat(txn.amount_aud);
      }
      if (txn.type_of_fuel === 'UNLEADED PETROL') {
        petrolLiters += parseFloat(txn.fuel_usage);
        petrolAmount += parseFloat(txn.amount_aud);
      }
    });

    return {
      diesel: {
        liters: dieselLiters.toFixed(2),
        amount: dieselAmount.toFixed(2),
      },
      petrol: {
        liters: petrolLiters.toFixed(2),
        amount: petrolAmount.toFixed(2),
      },
      totalAmount: (dieselAmount + petrolAmount).toFixed(2),
      totalLiters: (dieselLiters + petrolLiters).toFixed(2)
    };
  };

  // Calculate totals for the filtered (current month) transactions
  const totals = calculateTotals(filteredTransactions);

  // Calculate total across all months for overall statistics
  const overallTotals = calculateTotals(transactions); // Use all transactions

  const data = [
    { name: 'Diesel', value: parseFloat(totals.diesel.liters), amount: totals.diesel.amount },
    { name: 'Petrol', value: parseFloat(totals.petrol.liters), amount: totals.petrol.amount }
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  const columns = [
    { field: 'sn', headerName: 'S/N', width: 70 },
    { field: 'card_no', headerName: 'Card Number', width: 150 },
    { field: 'date', headerName: 'Date', width: 130 },
    { field: 'type_of_fuel', headerName: 'Type of Fuel', width: 150 },
    { field: 'fuel_usage', headerName: 'Fuel Usage (Litres)', width: 180 },
    { field: 'amount_aud', headerName: 'Amount (AUD)', width: 150 },
    { field: 'docket_no', headerName: 'Docket Number', width: 150 },
  ];

  const rows = missingTransactions.map((txn, idx) => ({
    id: txn.sn, // 'id' is required by DataGrid
    sn: idx + 1,
    card_no: txn.card_no,
    date: txn.date,
    type_of_fuel: txn.type_of_fuel,
    fuel_usage: txn.fuel_usage,
    amount_aud: txn.amount_aud,
    docket_no: txn.docket_no,
  }));

  // Helper function to check if the month is over
  const isMonthOver = () => {
    const currentDate = new Date();
    const [filterYear, filterMonthValue] = filterMonth.split('-').map(Number);
    return currentDate.getFullYear() > filterYear || (currentDate.getFullYear() === filterYear && currentDate.getMonth() + 1 > filterMonthValue);
  };

  // Dynamic title message based on verification status
  const fuelUsageTitle = () => {
    const [year, month] = filterMonth.split('-'); // Split the input string
    const date = new Date(year, month - 1); // Create a Date object (month is 0-indexed)
    const options = { year: 'numeric', month: 'long' }; // Options for formatting
    const formattedDate = date.toLocaleDateString('en-US', options); // Format date

    if (!isMonthOver()) {
      return `Fuel Usage for ${formattedDate} (Monthly CoR not due yet)`;
    } else if (talliedCount === 0) {
      return `Fuel Usage for ${formattedDate} (All manually verified)`;
    } else {
      return `Fuel Usage for ${formattedDate} (${talliedCount}/45 not manually verified)`;
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom paddingBottom='20px'>Fuel Usage Statistics</Typography>

      <Grid container spacing={2} alignItems="center" marginBottom="20px">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="month"
            value={filterMonth}
            onChange={e => setFilterMonth(e.target.value)}
            label="Filter by Month"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} md={4} style={{ paddingLeft: '20px', marginBottom: '20px' }}>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value, name, props) => [<div><b>{props.payload.name}</b> <br/> {value} Litres <br/> ${props.payload.amount} AUD</div>]} />
          </PieChart>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6">{fuelUsageTitle()}</Typography>
          <Typography>Total Fuel Consumed: {totals.totalLiters} Litres</Typography>
          <Typography>Total Amount Spent: ${totals.totalAmount} AUD</Typography>

          <Box marginTop={4}>
            <Typography variant="h6">Total Fuel Usage (All Time)</Typography>
            <Typography>Total Fuel Consumed: {overallTotals.totalLiters} Litres</Typography>
            <Typography>Total Amount Spent: ${overallTotals.totalAmount} AUD</Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom paddingBottom='20px' paddingTop='20px'>Missing Transactions</Typography>

      <Paper style={{ height: 400, width: '100%', marginTop: '20px' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5, 10, 20]} />
      </Paper>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Typography, Grid, Paper, Checkbox, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const CoR = () => {
  const [allTalliedStatuses, setAllTalliedStatuses] = useState([]); // Store all statuses from API
  const [transactionsOnHand, setTransactionsOnHand] = useState([]);
  const [transactionsInPortal, setTransactionsInPortal] = useState([]);
  const [filterMonth, setFilterMonth] = useState(new Date().toISOString().substring(0, 7));
  const [manualChecks, setManualChecks] = useState({});
  const [talliedStatuses, setTalliedStatuses] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all data when the component mounts
  useEffect(() => {
    fetchAllData();
  }, []);

  // Reconstruct the rows when data changes
  useEffect(() => {
    if (!loading) {
      filterAndConstructRows(); // Filter data based on selected month
    }
  }, [manualChecks, talliedStatuses, transactionsOnHand, transactionsInPortal, filterMonth, loading]);

  // Fetch all data from the backend
  const fetchAllData = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchTalliedStatuses(), fetchTransactionsOnHand(), fetchTransactionsInPortal()]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  // Fetch all tallied statuses from the backend (without month filtering)
  const fetchTalliedStatuses = async () => {
    try {
      const response = await axios.get(`/api/get-tallied-status`);
      const talliedData = response.data;

      // Store all statuses for filtering later
      setAllTalliedStatuses(talliedData);

      const checks = {};
      const statuses = {};

      console.log(talliedData);

      // Set initial manualChecks and talliedStatuses from the full data
      talliedData.forEach(entry => {
        checks[entry.card_no] = entry.manually_checked === 1;
        statuses[entry.card_no] = entry.tallied_status;
      });

      setManualChecks(checks);
      setTalliedStatuses(statuses);
    } catch (error) {
      console.error('Error fetching tallied statuses:', error);
    }
  };

  // Fetch transactions on hand from the backend
  const fetchTransactionsOnHand = async () => {
    try {
      const response = await axios.get('/api/get-input-transactions');
      setTransactionsOnHand(response.data);
    } catch (error) {
      console.error('Error fetching transactions on hand:', error);
    }
  };

  // Fetch transactions in the portal from the backend
  const fetchTransactionsInPortal = async () => {
    try {
      const response = await axios.get('/api/get-reference-transactions');
      setTransactionsInPortal(response.data);
    } catch (error) {
      console.error('Error fetching transactions in portal:', error);
    }
  };

  // Filter tallied statuses and construct rows based on selected month
  const filterAndConstructRows = () => {
    const filteredStatuses = allTalliedStatuses.filter(status => {
      const [year, month] = filterMonth.split('-').map(Number);
      const [statusYear, statusMonth] = status.month.split('-').map(Number);

      // Compare the month and year from the filter with the data's month and year
      return year === statusYear && month === statusMonth;
    });

    // Create checks and statuses based on filtered data
    const checks = {};
    const statuses = {};

    filteredStatuses.forEach(entry => {
      checks[entry.card_no] = entry.manually_checked === 1;
      statuses[entry.card_no] = entry.tallied_status;
    });

    setManualChecks(checks);
    setTalliedStatuses(statuses);
    constructRows();
  };

  // Update tallied status in the backend
  const updateTalliedStatus = async (cardNo, manuallyChecked, receiptsOnHand, receiptsInPortal) => {
    const missingReceipts = receiptsInPortal - receiptsOnHand;

    let talliedStatus = 'NOT TALLIED';
    if (missingReceipts === 0) {
      talliedStatus = manuallyChecked ? 'TALLIED' : 'PENDING MANUAL VERIFICATION';
    }

    try {
      console.log({
        card_no: cardNo,
        month: filterMonth,
        receipts_on_hand: receiptsOnHand,
        receipts_in_portal: receiptsInPortal,
        missing_receipts: missingReceipts,
        manually_checked: manuallyChecked ? 1 : 0,
        tallied_status: talliedStatus
      });
      await axios.post('/api/update-tallied-status', {
        card_no: cardNo,
        month: filterMonth,
        receipts_on_hand: receiptsOnHand,
        receipts_in_portal: receiptsInPortal,
        missing_receipts: missingReceipts,
        manually_checked: manuallyChecked ? 1 : 0,
        tallied_status: talliedStatus
      });

      // After updating, refresh data
      fetchTalliedStatuses();
    } catch (error) {
      console.error('Error updating tallied status:', error);
    }
  };

  // Filter transactions by the selected month
  const filterByMonth = (transactions) => {
    return transactions.filter(txn => {
      const [txnDay, txnMonth, txnYear] = txn.date.split('/').map(Number);
      const [filterYear, filterMonthValue] = filterMonth.split('-').map(Number);
      return txnYear === filterYear && txnMonth === filterMonthValue;
    });
  };

  // Handle checkbox changes (toggle manually checked status)
  const handleCheckboxChange = async (cardNo, manuallyChecked, numberOfReceiptsOnHand, numberOfReceiptsInPortal) => {
    const newChecked = !manuallyChecked;

    // Update the local state to reflect the change
    setManualChecks(prev => ({
      ...prev,
      [cardNo]: newChecked
    }));

    // Update the tallied status in the backend
    await updateTalliedStatus(cardNo, newChecked, numberOfReceiptsOnHand, numberOfReceiptsInPortal);
  };

  // Filter the transactions for the current month
  const filteredTransactionsOnHand = filterByMonth(transactionsOnHand);
  const filteredTransactionsInPortal = filterByMonth(transactionsInPortal);

  // Construct the rows for the DataGrid
  const constructRows = () => {
    const newRows = Array.from({ length: 45 }, (_, index) => {
      const cardNo = Number(index + 1);
      const receiptsOnHand = filteredTransactionsOnHand.filter(txn => txn.card_no === `RSAF ${cardNo}`);
      const receiptsInPortal = filteredTransactionsInPortal.filter(txn => txn.card_no === `RSAF ${cardNo}`);
  
      const numberOfReceiptsOnHand = receiptsOnHand.length;
      const numberOfReceiptsInPortal = receiptsInPortal.length;
      const numberOfMissingReceipts = numberOfReceiptsInPortal - numberOfReceiptsOnHand;
  
      const manuallyChecked = manualChecks[cardNo] || false;
      let talliedStatus = talliedStatuses[cardNo] || 'NOT TALLIED';
      let backgroundColor;
  
      if (numberOfMissingReceipts === 0) {
        if (manuallyChecked) {
          talliedStatus = 'TALLIED';
          backgroundColor = '#cbf5dd'; // Light green
        } else {
          talliedStatus = 'PENDING MANUAL VERIFICATION';
          backgroundColor = '#ffffc5'; // Light yellow for pending manual verification
        }
      } else {
        // If there are missing receipts, set to NOT TALLIED with light red
        talliedStatus = 'NOT TALLIED';
        backgroundColor = '#ff7f7f'; // Light red
      }
  
      return {
        id: cardNo,
        card_no: `RSAF ${cardNo}`,
        receipts_on_hand: numberOfReceiptsOnHand,
        receipts_in_portal: numberOfReceiptsInPortal,
        missing_receipts: numberOfMissingReceipts,
        manually_checked: manuallyChecked,
        tallied: { status: talliedStatus, backgroundColor },
        handleCheckboxChange: () => handleCheckboxChange(cardNo, manuallyChecked, numberOfReceiptsOnHand, numberOfReceiptsInPortal)
      };
    });
  
    setRows(newRows);
  };  

  // Define the columns for the DataGrid
  const columns = [
    { field: 'card_no', headerName: 'Card Number', width: 150 },
    { field: 'receipts_on_hand', headerName: 'Receipts On Hand', width: 180 },
    { field: 'receipts_in_portal', headerName: 'Receipts In Portal', width: 180 },
    { field: 'missing_receipts', headerName: 'Missing Receipts', width: 180 },
    {
      field: 'manually_checked',
      headerName: 'Manually Checked',
      width: 180,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.manually_checked}
          onChange={params.row.handleCheckboxChange}
        />
      ),
    },
    {
      field: 'tallied',
      headerName: 'Tallied Status',
      width: 200,
      renderCell: (params) => (
        <div style={{
          backgroundColor: params.row.tallied.backgroundColor,
          color: 'black',
          padding: '10px',
          borderRadius: '4px',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          whiteSpace: 'normal', // Allow text to wrap
          overflow: 'hidden', // Hide overflow
          wordBreak: 'break-word', // Break words to prevent overflow
          lineHeight: '1.2'
        }}>
          {params.row.tallied.status}
        </div>
      )
    }
  ];

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom paddingBottom='20px'>Monthly CoR Check</Typography>

      <Grid container spacing={2} alignItems="center" marginBottom="20px">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="month"
            value={filterMonth}
            onChange={e => setFilterMonth(e.target.value)}
            label="Filter by Month"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Paper style={{ height: 600, width: '100%', marginTop: '20px' }}>
        <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10, 20, 30]} />
      </Paper>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Modal, TextField, Switch, FormControlLabel, Grid, CircularProgress } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

export const ReferenceTransactionPage = () => {
  const [referenceTransactions, setReferenceTransactions] = useState([]);
  const [missingTransactions, setMissingTransactions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filterMonth, setFilterMonth] = useState(new Date().toISOString().substring(0, 7)); // Default to current month
  const [showOnlyMissing, setShowOnlyMissing] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false); 

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    setLoading(true);
    setError(null);
    try {
      const referenceRes = await axios.get('/api/get-reference-transactions');
      setReferenceTransactions(referenceRes.data || []);

      const missingRes = await axios.get('/api/get-missing-transactions');
      setMissingTransactions(missingRes.data || []);
    } catch (error) {
      setError('Error fetching transactions');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await axios.post('/api/upload-reference-file', formData);
      alert('File uploaded successfully!');
      setIsModalOpen(false);
      fetchTransactions();
    } catch (error) {
      alert('Failed to upload file.');
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const isMissingTransaction = (txn) => {
    return missingTransactions.some((missingTxn) =>
      missingTxn.card_no === txn.card_no &&
      missingTxn.date === txn.date &&
      missingTxn.type_of_fuel === txn.type_of_fuel &&
      missingTxn.fuel_usage === txn.fuel_usage &&
      missingTxn.amount_aud === txn.amount_aud &&
      missingTxn.docket_no === txn.docket_no
    );
  };

  const filteredTransactions = referenceTransactions.filter(txn => {
    const [txnDay, txnMonth, txnYear] = txn.date.split('/').map(Number);
    const [filterYear, filterMonthValue] = filterMonth.split('-').map(Number);
    return txnYear === filterYear && txnMonth === filterMonthValue;
  });

  const displayedTransactions = showOnlyMissing
    ? filteredTransactions.filter(isMissingTransaction)
    : filteredTransactions;

  const columns = [
    { field: 'sn', headerName: 'S/N', width: 50 },
    { field: 'card_no', headerName: 'Card No', width: 130 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'type_of_fuel', headerName: 'Type of Fuel', width: 150 },
    { field: 'fuel_usage', headerName: 'Fuel Usage (L)', width: 150 },
    { field: 'docket_no', headerName: 'Docket No', width: 130 },
    { field: 'amount_aud', headerName: 'Amount ($AUD)', width: 150 },
    { field: 'transaction_location', headerName: 'Transaction Location', width: 180 }
  ];

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography variant="h5">Reference Transactions</Typography>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        {/* Date selector and toggle switch flushed left */}
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={2}
            flexWrap="wrap"
          >
            <TextField
              type="month"
              label="Filter by Month"
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              fullWidth={false}
              InputLabelProps={{ shrink: true }}
              style={{ minWidth: '200px' }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyMissing}
                  onChange={(e) => setShowOnlyMissing(e.target.checked)}
                  color="primary"
                />
              }
              label="Show Only Missing Transactions"
            />
          </Box>
        </Grid>

        {/* Upload button flushed right */}
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<Upload />}
              onClick={() => setIsModalOpen(true)}
              fullWidth={true}
              style={{ maxWidth: '250px' }}
            >
              UPLOAD EXCEL FILE
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box style={{ backgroundColor: 'white', padding: '20px', margin: '100px auto', width: '400px' }}>
          <Typography variant="h6">Upload Reference Transaction File</Typography>
          <input type="file" accept=".csv" onChange={handleFileChange} style={{ marginTop: '10px' }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            style={{ marginTop: '20px' }}
            fullWidth
            disabled={uploading} // Disable button when uploading
            startIcon={uploading && <CircularProgress size={20} />} // Show loading spinner
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Box>
      </Modal>

      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={displayedTransactions.map((txn, idx) => ({ id: idx, ...txn }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={(params) => isMissingTransaction(params.row) ? 'missing-row' : ''}
          sx={{
            '& .missing-row': {
              backgroundColor: '#ffcbd1',
            },
          }}
        />
      </div>
    </div>
  );
};
